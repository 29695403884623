<template>
  <v-container>
    <v-form ref="form" lazy-validation>
      <v-dialog
        v-model="showForm"
        max-width="600px"
        persistent
        transition="dialog-top-transition"
      >
        <v-card>
          <v-card-title>
            {{ "Add New " + test_type + " test" }}
            <v-spacer></v-spacer>
          </v-card-title>
          <v-card-subtitle>
            {{
              "Periode From " +
              dateam($store.state.planning[0].date_from) +
              " To " +
              dateam($store.state.planning[0].date_to)
            }}</v-card-subtitle
          >
          <v-card-text>
            <br />

            {{ "Justification" }}
            <v-textarea
              ref="comment"
              outlined
              dense
              v-model="accept_comment"
              :rules="[(v) => !!v || 'Justification required']"
              row-height="30"
              rows="2"
              variant="outlined"
              auto-grow
            ></v-textarea>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>

            <v-btn class="ma-1" color="blue darken-1" @click.stop="add_test()">
              Add
            </v-btn>

            <v-btn color="blue darken-1" @click.stop="close()" class="ma-1">
              Cancel
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-form>
    <confirmdialog ref="validation" />
    <v-snackbar
      v-model="snackbar"
      :timeout="snackbar_timeout"
      top
      :color="snackbar_color"
    >
      {{ snackbar_text }}
    </v-snackbar>
  </v-container>
</template>
<script>
import SAVE_EVENT from "../graphql/WellTest/APM/SaveEvent.gql";
import SaveTest from "../graphql/WellTest/APM/SaveTest.gql";
import { dateam } from "@/utils/functions.js";
export default {
  components: {
    confirmdialog: () => import("../components/ConfirmDialog.vue"),
  },
  props: {
    showForm: Boolean,
    well_id: Number,
    testtype_id: Number,
    teststypes_list: Array,
  },

  data() {
    return {
      dialog: false,
      comment_type: "1",
      comment: null,
      valid: true,
      progress: false,
      test_type: null,
      accept_comment: "",
      snackbar: false,
      snackbar_timeout: 2000,
      snackbar_text: "",
      snackbar_color: "primary",
    };
  },
  computed: {
    dateam() {
      return dateam;
    },
  },
  created() {
    let i = this.teststypes_list.findIndex((elm) => elm.id == this.testtype_id);
    if (i >= 0) {
      this.test_type = this.teststypes_list[i].description;
    }
  },
  methods: {
    close() {
      this.$emit("close");
    },

    async add_test() {
      if (this.$refs.form.validate()) {
        if (
          await this.$refs.validation.open("Confirmation", " Add test?", {
            color: "orange darken-3",
          })
        ) {
          let r = await this.$requette(
            SaveTest,
            {
              test: [
                {
                  well_id: this.well_id,
                  testtype_id: this.testtype_id,
                  planning_id: this.$store.state.planning[0].id,
                  statut_id: 0,
                },
              ],
            },
            "no-cache"
          );
          if (r.ok) {
            r = await this.$requette(
              SAVE_EVENT,
              {
                test_id: parseInt(r.data.SaveTest.id),
                user_id: this.$store.state.me.id,
                event_id: 2, // event returned
                event_statut_id: 0,
                comment: this.accept_comment,
              },
              "no-cache"
            );
            this.$emit("close");
            //this.test.statut_id = test_statut;
            this.$emit("update");
          }
        }
      }
    },
  },
};
</script>
